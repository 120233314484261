import React, { useState, useContext, useRef, useEffect } from "react";
import AppContext from "../../../../store/AppContext";
import { Input, Button, TextArea, Spinner, Select, Checkbox } from "../../..";
import { FormWrapper, InputWrapper, Message } from "./views";
import { translateFormData } from "../../../../store/actions";
import { countries } from "../../../../store/static";

const SubmitForm = () => {
  const { state, handleUpdateState } = useContext(AppContext);
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [postcodeLoading, setPostcodeLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const headingRef = useRef(null);

  const executeScroll = () => headingRef.current.scrollIntoView();

  const handleInputChange = (e, name) => {
    handleUpdateState({
      customer: {
        ...state.customer,
        [name]: e.target.value,
        ...(e.target.name === "country-select" && { country_code: countries[e.target.value] }),
      },
    });

    console.log(state);
  };

  const handleCheckboxChange = (e) => {
    handleUpdateState({
      customer: {
        ...state.customer,
        marketing_opt_in: e.target.checked,
      },
    });
  };

  const handlePostcodeLookup = () => {
    setAddresses([]);
    setPostcodeError("");

    if (state.customer.country_code !== "UK" && state.customer.address_search_query.length < 20) {
      return setPostcodeError(
        "Please add more detail so we can find your address. Make sure to include address number, street name and postcode."
      );
    }

    handleUpdateState({
      customer: {
        ...state.customer,
        address: "",
      },
    });

    setPostcodeLoading(true);

    if (state.customer.address_search_query) {
      fetch(
        `https://ws.postcoder.com/pcw/PCW65-PCFWZ-K2F7G-X5X6V/address/${state.customer.country_code.toLowerCase()}/${encodeURIComponent(
          state.customer.address_search_query.toLowerCase()
        )}`
      )
        .then((res) => res.json())
        .then((json) => {
          setAddresses(json);
          console.log(json);
          setPostcodeLoading(false);
          if (json.length === 0)
            setPostcodeError("No addresses found, please check your address is entered correctly.");
        })
        .catch((err) => {
          console.log(err);
          setPostcodeLoading(false);
        });
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    setSuccess(false);
    setError("");
    e.preventDefault();

    const postData = translateFormData(state);

    const url = "https://api.isopodconfigurator.com/submit";
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        setLoading(false);
        setSuccess(true);
      }
    };

    xhr.send(JSON.stringify(postData));
  };

  useEffect(() => {
    executeScroll();
  }, []);

  return (
    <>
      <FormWrapper>
        <h3 ref={headingRef}>Enter your details</h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <InputWrapper>
            <Input
              required
              type="text"
              label="First name"
              name="firstName"
              handleChange={(e) => handleInputChange(e, "firstName")}
              value={state.customer.firstName}
            />
            <Input
              required
              type="text"
              label="Last name"
              name="lastName"
              handleChange={(e) => handleInputChange(e, "lastName")}
              value={state.customer.lastName}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              required
              type="text"
              label="Company name"
              name="company"
              handleChange={(e) => handleInputChange(e, "company")}
              value={state.customer.company}
            />
            <Input
              required
              type="text"
              label="Job title"
              name="job_title"
              handleChange={(e) => handleInputChange(e, "job_title")}
              value={state.customer.job_title}
            />
          </InputWrapper>
          <Input
            required
            type="text"
            label="Industry"
            name="industry"
            handleChange={(e) => handleInputChange(e, "industry")}
            value={state.customer.industry}
          />
          <Input
            required
            type="email"
            label="Email"
            name="email"
            handleChange={(e) => handleInputChange(e, "email")}
            value={state.customer.email}
          />
          <Input
            required
            type="number"
            hideNumberSpinner
            label="Contact Number"
            inputMode="numeric"
            name="contactNumber"
            handleChange={(e) => handleInputChange(e, "contactNumber")}
            value={state.customer.contactNumber}
          />
          <Select
              label="Where did you find us?"
              name="where-did-you-find-us"
              handleChange={(e) => handleInputChange(e, "how_did_you_find_us")}
              value={state.customer.how_did_you_find_us}
            >
            <option disabled value="">Please select...</option>
            <option value="Isopod Website">Isopod Website</option>
            <option value="Google Search">Google Search</option>
            <option value="Advertising">Advertising</option>
            <option value="Word of Mouth">Word of Mouth</option>
            <option value="LinkedIn">LinkedIn</option>
            <option value="Other">Other</option>
          </Select>
          <hr />
          <h2>Install location</h2>
          <InputWrapper>
            <Select
              label="Choose your country"
              name="country-select"
              handleChange={(e) => handleInputChange(e, "country")}
              value={state.customer.country}
            >
              <option disabled value="">
                Please select...
              </option>
              <option value="United Kingdom">United Kingdom</option>
              <option disabled>──────────</option>
              {Object.keys(countries)
                .sort()
                .map((country, i) => {
                  return (
                    country !== "United Kingdom" && (
                      <option key={i} value={country}>
                        {country}
                      </option>
                    )
                  );
                })}
            </Select>
          </InputWrapper>
          {state.customer.country && (
            <InputWrapper isPostcodeLookup>
              <Input
                required
                type="text"
                placeholder={
                  state.customer.country_code === "UK"
                    ? "Enter your postcode"
                    : "Include number, street name and postcode"
                }
                label={state.customer.country_code === "UK" ? "Install location postcode" : "Search for address"}
                name="address_search_query"
                handleChange={(e) => handleInputChange(e, "address_search_query")}
                value={state.customer.address_search_query}
              />
              <Button className="wide" type="button" onClick={handlePostcodeLookup}>
                Find Address
                {postcodeLoading && <Spinner />}
              </Button>
            </InputWrapper>
          )}
          {postcodeError && <Message type="error">{postcodeError}</Message>}
          {addresses.length > 0 && (
            <Select
              required
              label="Select your address"
              name="address"
              handleChange={(e) => handleInputChange(e, "address")}
              value={state.customer.address}
            >
              <option value="" disabled>
                Please select...
              </option>
              {addresses.map((address, key) => (
                <option value={address.summaryline} key={key}>
                  {address.summaryline}
                </option>
              ))}
            </Select>
          )}
          <hr />
          <TextArea
            label="Any further details"
            name="details"
            handleChange={(e) => handleInputChange(e, "details")}
            value={state.customer.details}
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              type="checkbox"
              name="marketing_opt_in"
              onChange={(e) => handleCheckboxChange(e)}
              checked={state.customer.marketing_opt_in}
            />
            <label htmlFor="marketing_opt_in" style={{ marginLeft: 20, marginTop: 3 }}>
              I am happy to receive further communications from Guardtech
            </label>
          </div>
          <Button type="submit">
            Submit
            {loading && <Spinner />}
          </Button>
        </form>
        {success && (
          <Message type="success">
            Thank you, your configuration has been sent. Please check your junk email folder if the email hasn't
            appeared in your inbox.
          </Message>
        )}
        {error && <Message type="error">{error}</Message>}
      </FormWrapper>
    </>
  );
};

export default SubmitForm;
